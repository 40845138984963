import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    $(this.element).find('[data-form-group-toggle]').change(this._sync.bind(this)).change();
  }

  _sync() {
    $(this.element).find('[data-form-group-toggle]').each((_, element) => {
      const value = $(element).val();
      const checked = $(element).is(':checked');

      $(this.element)
        .find(`[data-form-group-checked="${value}"]`)
        .toggle(checked)
        .not(':visible')
        .find('input,textarea,select')
        .addBack('input,textarea,select')
        .val('');

      $(this.element)
        .find(`[data-form-group-unchecked="${value}"]`)
        .toggle(!checked)
        .not(':visible')
        .find('input,textarea,select')
        .addBack('input,textarea,select')
        .val('');
    });
  }
}
