import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['diagnosis', 'confidence', 'label', 'rest', 'add', 'error', 'submit'];

  connect() {
    this.sync();
    document.addEventListener('ajax:complete', this.sync.bind(this));
  }

  submit(event) {
    if (this.hasZeroConfidences || this.hasEmptyDiagnoses || this.hasDuplicateDiagnoses) {
      event.preventDefault();
    } else {
      // turn off turbolinks due to a noticable slowdown after ~30
      // consecutive responses
      this.element.dataset.remote = false;
    }
  }

  add() {
    this.element.insertAdjacentHTML(
      'beforeend',
      `<input type="hidden" name="review_response[diagnoses_attributes][${new Date().getTime()}][confidence]" value="0">`
    );
  }

  remove(event) {
    event.target.closest('tr').querySelectorAll('input,select,textarea').forEach(element => element.disabled = true);
  }

  get hasZeroConfidences() {
    return this.confidenceTargets.some(element => element.value === '0');
  }

  get hasEmptyDiagnoses() {
    return this.diagnosisTargets.some(element => element.value === '');
  }

  get hasDuplicateDiagnoses() {
    const values = this.diagnosisTargets.map(element => element.value);

    return new Set(values).size !== values.length;
  }

  get rest() {
    return this.confidenceTargets.reduce((result, element) => result - parseInt(element.value, 10), 100);
  }

  sync(event) {
    if (event && !this.hasZeroConfidences && this.rest < 0) {
      event.target.value = parseInt(event.target.value, 10) + this.rest;
    }

    this._syncSelect2();
    this._syncSubmit();
    this._syncRest();
    this._syncLabels();
    this._syncAdd();
    this._syncErrors();
  }

  _syncSelect2() {
    $(this.diagnosisTargets)
      .select2({
        minimumInputLength: 3,
        ajax: {
          url: this.data.get('diagnosesPath'),
          delay: 250
        }})
      .change(this.sync.bind(this));
  }

  _syncSubmit() {
    this.submitTarget.dataset.confirm = this.diagnosisTargets.length ? '' : 'Are you sure?';
    this.submitTarget.disabled = this.hasZeroConfidences || this.hasEmptyDiagnoses || this.hasDuplicateDiagnoses;
  }

  _syncRest() {
    if (this.hasRestTarget) {
      this.restTarget.innerText = this.rest;
    }
  }

  _syncLabels() {
    this.labelTargets.forEach((element, i) => element.innerText = `${this.confidenceTargets[i].value}%`);
  }

  _syncAdd() {
    this.addTarget.disabled = this.hasZeroConfidences || this.hasEmptyDiagnoses || this.rest === 0 || this.confidenceTargets.length >= parseInt(this.data.get('max'), 10);
  }

  _syncErrors() {
    if (this.hasZeroConfidences) {
      this.errorTarget.innerText = 'Confidence can\'t be 0%. Please move the slider to change the value.';
    }

    if (this.hasEmptyDiagnoses) {
      this.errorTarget.innerText = 'Diagnosis can\'t be empty. Please start typing to add a diagnosis.';
    }

    if (this.hasDuplicateDiagnoses) {
      this.errorTarget.innerText = 'Diagnoses must be unique. Please make sure no duplicate diagnoses exist on the list.';
    }

    this.errorTarget.classList.toggle('invisible', !this.hasZeroConfidences && !this.hasEmptyDiagnoses && !this.hasDuplicateDiagnoses);
  }
}
