import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  upload(event) {
    const [file] = event.target.files;
    const reader = new FileReader();

    reader.onload = (event) => this.inputTarget.value = event.target.result;
    reader.readAsText(file);
  }
}
