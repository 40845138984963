import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('direct-upload:initialize', this._directUploadInitialize.bind(this));
    this.element.addEventListener('direct-upload:start', this._directUploadStart.bind(this));
    this.element.addEventListener('direct-upload:progress', this._directUploadProgress.bind(this));
    this.element.addEventListener('direct-upload:error', this._directUploadError.bind(this));
    this.element.addEventListener('direct-upload:end', this._directUploadEnd.bind(this));
  }

  _directUploadInitialize(event) {
    const { detail } = event;
    const { id, file } = detail;

    this.element.insertAdjacentHTML('beforeend', `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span id="direct-upload-filename-${id}" class="direct-upload__filename">${file.name} (0%)</span>
      </div>
    `);
  }

  _directUploadStart(event) {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.remove('direct-upload--pending');
  }

  _directUploadProgress(event) {
    const { id, progress, file } = event.detail;
    const progressElement = document.getElementById(`direct-upload-progress-${id}`);
    const filenameElement = document.getElementById(`direct-upload-filename-${id}`);
    progressElement.style.width = `${progress}%`;
    filenameElement.innerText = `${file.name} (${Math.round(progress)}%)`;
  }

  _directUploadError(event) {
    event.preventDefault();
    const { id, error } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--error');
    element.setAttribute('title', error);
  }

  _directUploadEnd(event) {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--complete');
  }
}
