import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content'];

  copy() {
    this.contentTarget.select();
    document.execCommand('copy');
  }
}
