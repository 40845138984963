import { Controller } from 'stimulus';
import Choices from 'choices.js';

export default class extends Controller {
  connect() {
    new Choices(this.element, {
      removeItemButton: this.data.get('removeItemButton'),
      searchPlaceholderValue: this.data.get('searchPlaceholderValue'),
      position: this.data.get('position')
    });
  }
}
